<template>
	<!-- modal login-->
	<b-modal
		id="datasource-modal"
		v-model="show"
		:title="datasource_modal.datasource_id ? '编辑数据源（数据源ID：' + datasource_modal.datasource_id + '）' : '添加新数据源'"
		button-size="sm"
		cancel-title="取消"
		cancel-variant="outline-secondary"
		centered
		no-close-on-backdrop
		ok-title="确定"
		@ok.prevent="submitDatasource"
	>
		<b-form>
			<b-form-group>
				<label>数据源类型（Source Type）</label>
				<v-select
					v-model="datasource_modal.type"
					:class="!datasource_modal.type ? 'border-danger' : ''"
					:clearable="false"
					:options="[
						{ text: 'MySQL', value: 'mysql' },
						{ text: 'SqLite', value: 'sqlite' },
						{ text: 'SqlServer', value: 'sqlsrv' },
						{ text: 'PostgreSQL', value: 'pgsql' },
					]"
					:reduce="(text) => text.value"
					class="rounded"
					label="text"
				/>
			</b-form-group>

			<b-form-group>
				<label>连接名（Connection Name）</label>
				<b-form-input v-model="datasource_modal.datasource_name" placeholder="请输入连接名" />
			</b-form-group>

			<b-form-group>
				<label>主机名或IP地址（Host）</label>
				<b-form-input v-model="datasource_modal.hostname" placeholder="请输入数据库主机名或IP地址" type="password" />
			</b-form-group>
			<b-form-group>
				<label>端口号（Port）</label>
				<b-form-input v-model="datasource_modal.hostport" placeholder="请输入数据库端口号" />
			</b-form-group>
			<b-form-group>
				<label>数据库名（Database Name）</label>
				<b-form-input v-model="datasource_modal.database" placeholder="请输入数据库名" type="password" />
			</b-form-group>
			<b-form-group>
				<label>用户名（User Name）</label>
				<b-form-input v-model="datasource_modal.username" placeholder="请输入用户名" type="password" />
			</b-form-group>
			<b-form-group>
				<label>密码（Password）</label>
				<b-input-group>
					<b-form-input v-model="datasource_modal.password" :type="passwordFieldType" placeholder="请输入密码" type="password" />
					<!--					<b-input-group-append is-text>-->
					<!--						<feather-icon-->
					<!--							:icon="-->
					<!--                passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'-->
					<!--              "-->
					<!--							class="cursor-pointer"-->
					<!--							@click="togglePasswordVisibility"-->
					<!--						/>-->
					<!--					</b-input-group-append>-->
				</b-input-group>
			</b-form-group>
		</b-form>
	</b-modal>
</template>

<script>
	import { togglePasswordVisibility } from "@/@core/mixins/ui/forms";
	import vSelect from "vue-select";
	import Ripple from "vue-ripple-directive";
	import useDatasourceModal from "./DatasourceModalUse";

	export default {
		name: "DatasourceModal",
		components: {
			vSelect,
		},
		directives: {
			Ripple,
		},
		mixins: [togglePasswordVisibility],
		setup(props, ctx) {
			const { show, datasource_modal, submitDatasource } = useDatasourceModal();

			return {
				show,
				datasource_modal,
				submitDatasource,
			};
		},
	};
</script>

<style scoped></style>
