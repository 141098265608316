import { computed, reactive, toRefs, watch } from "@vue/composition-api";
import store from "@/store";
import { showToast } from "@/libs/utils/showToast";
import { routerParams } from "@/libs/utils/routerParams";

export default function datasourceModal() {
	const event = reactive({
		show: false,
		project_id: computed(() => {
			return routerParams("project_id");
		}),
		datasource_modal: computed(() => {
			return store.state["panel-group"]["datasource-modal"];
		}),
	});
	watch(
		() => event.show,
		(value) => {
			if (!!value) {
				fetchDatasource();
			}
		}
	);

	const submitDatasource = () => {
		if (event.datasource_modal.datasource_id) {
			updateDatasource();
		} else {
			addDatasource();
		}
	};
	/**
	 * 新增Datasource
	 */
	const addDatasource = () => {
		store
			.dispatch(
				"panel-group/addDatasource",
				Object.assign(
					{
						project_id: event.project_id,
					},
					event.datasource_modal
				)
			)
			.then((response) => {
				showToast(null, "成功", "数据源新增成功！");
				const { list } = response.data;
				event.show = false;
				formatDatasourceList(list);
			})
			.catch((error) => {
				showToast(error);
			});
	};
	/**
	 * 更新datasource
	 */
	const updateDatasource = () => {
		store
			.dispatch(
				"panel-group/updateDatasource",
				Object.assign(
					{
						project_id: event.project_id,
					},
					event.datasource_modal
				)
			)
			.then((response) => {
				showToast(null, "成功", "数据源更新成功！");
				const { list } = response.data;
				event.show = false;
				formatDatasourceList(list);
			})
			.catch((error) => {
				showToast(error);
			});
	};

	const fetchDatasource = () => {
		const datasource_id = event.datasource_modal.datasource_id;
		if (datasource_id) {
			showToast(null, "提示", "正在获取数据源详情");
			store
				.dispatch("panel-group/fetchDatasource", {
					datasource_id: datasource_id,
				})
				.then((response) => {
					showToast(null, "成功", "数据源详情获取成功！");
					store.commit("panel-group/UPDATE_DATASOURCE_MODAL", response.data);
				})
				.catch((error) => {
					showToast(error);
				});
		}
	};

	/**
	 * 整理datasource_list数据格式，用于select
	 * @param list
	 */
	const formatDatasourceList = (list) => {
		list.map((item) => {
			item.value = item.datasource_id;
			item.text = item.datasource_name;
		});
		store.commit("panel-group/UPDATE_DATASOURCE_LIST", list);
	};

	return {
		...toRefs(event),
		submitDatasource,
	};
}
