<template>
    <!------------------------------------------------
    组件配置侧边栏：
    用于配置组件的数据和样式等参数
    ------------------------------------------------->
    <b-modal
        id="panel-modal"
        v-model="show"
        button-size="sm"
        cancel-title="取消"
        cancel-variant="outline-primary"
        centered
        no-close-on-backdrop
        ok-title="提交"
        size="lg"
        title="组件配置"
        @ok="submit"
    >
        <h5 class="text-center my-2 font-weight-bolder">
            {{
                panel_modal.panel_id
                    ? "编辑组件（ID: " + panel_modal.panel_id + "）"
                    : "添加新组件"
            }}
        </h5>
        <app-timeline>
            <app-timeline-item variant="primary">
                <h5>结果预览</h5>
                <b-form-checkbox
                    v-model="preview_show"
                    class="position-absolute"
                    style="right: 0; top: -0.5rem"
                    switch
                >
                    {{ preview_show ? "显示" : "隐藏" }}
                </b-form-checkbox>
                <b-card v-show="preview_show" style="height: 18rem">
                    <vue-perfect-scrollbar
                        :id="'panel-' + panel_modal.panel_id + '-preview'"
                        class="scroll-area h-100"
                    >
                        <component
                            :is="
                                'panel-component-' +
                                panel_modal.type.toLowerCase()
                            "
                            :key="'panel-preview-' + panel_modal.panel_id"
                            :item="panel_modal"
                            :preview="true"
                            class="w-100 h-100 no-drag zindex-0"
                        ></component>
                    </vue-perfect-scrollbar>
                </b-card>
            </app-timeline-item>

            <app-timeline-item variant="primary">
                <h5>图表名称</h5>
                <b-form-group>
                    <b-form-input
                        v-model="panel_modal.panel_name"
                        placeholder="请输入组件名称"
                    />
                </b-form-group>
            </app-timeline-item>

            <app-timeline-item variant="primary">
                <h5>组件类型</h5>
                <b-form-group>
                    <v-select
                        v-model="panel_modal.type"
                        :clearable="false"
                        :options="panel_type_list"
                        :reduce="(text) => text.value"
                        class="rounded"
                        disabled
                        label="text"
                    />
                </b-form-group>
            </app-timeline-item>

            <app-timeline-item v-if="show_panel_modal_data" variant="primary">
                <h5>数据配置</h5>
                <!--引入数据配置组件-->
                <panel-modal-data
                    :key="'panel-data-' + panel_modal.panel_id"
                    :item="panel_modal"
                    class="w-100 h-100"
                ></panel-modal-data>
            </app-timeline-item>

            <app-timeline-item variant="primary">
                <h5>参数配置</h5>
                <div class="text-right">
                    <b-dropdown
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        left
                        size="sm"
                        variant="primary"
                    >
                        <template v-slot:button-content>
                            <feather-icon
                                :icon="
                                    panel_modal.options_type === 1
                                        ? 'FileTextIcon'
                                        : panel_modal.options_type === 2
                                        ? 'CodeIcon'
                                        : 'ColumnsIcon'
                                "
                                class="mr-50"
                            ></feather-icon>
                            显示模式
                        </template>
                        <b-dropdown-item @click="change_options_type(1)">
                            <feather-icon
                                class="mr-50"
                                icon="FileTextIcon"
                            ></feather-icon>
                            <span>表单</span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="change_options_type(2)">
                            <feather-icon
                                class="mr-50"
                                icon="CodeIcon"
                            ></feather-icon>
                            <span>代码</span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="change_options_type(3)">
                            <feather-icon
                                class="mr-50"
                                icon="ColumnsIcon"
                            ></feather-icon>
                            <span>分栏</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
                <!--引入参数配置组件-->
                <panel-modal-options
                    :key="'panel-options-' + panel_modal.panel_id"
                    :item="panel_modal"
                    class="w-100 h-100"
                ></panel-modal-options>
            </app-timeline-item>
        </app-timeline>
    </b-modal>
</template>

<script>
import vSelect from "vue-select";
import AppTimeline from "@/@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@/@core/components/app-timeline/AppTimelineItem.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";
import { computed, reactive, toRefs, watch } from "@vue/composition-api";
import store from "@/store";
import {
    panel_default,
    panel_type_list,
} from "@/views/panel-group/defaultSettings";
import { showToast } from "@/libs/utils/showToast";
import PanelModalData from "./components/PanelModalData";
import PanelModalOptions from "./components/PanelModalOptions";
import { routerParams } from "@/libs/utils/routerParams";

export default {
    name: "PanelModal",
    directives: {
        Ripple,
    },
    components: {
        AppTimeline,
        AppTimelineItem,
        VuePerfectScrollbar,
        vSelect,
        PanelModalData,
        PanelModalOptions,
    },
    props: ["item"],
    setup(props, ctx) {
        // ------------------------------------------------
        // 引入响应数据
        // ------------------------------------------------
        const event = reactive({
            show: false,
            project_id: computed(() => {
                return routerParams("project_id");
            }),
            preview_show: true,
            panel_type_list: panel_type_list,
            datasource_list: computed(() => {
                return store.state["panel-group"]["datasource-list"];
            }),
            panel_modal: panel_default,
            show_panel_modal_data: computed(() => {
                const show_list = panel_type_list.filter(
                    (item) =>
                        item.value ===
                        store.state["panel-group"]["panel-modal"]["type"]
                );
                return show_list && show_list[0] ? show_list[0].query : false;
            }),
        });
        //修改配置类型
        const change_options_type = (type) => {
            event.panel_modal.options_type = type;
            store.commit(
                "panel-group/UPDATE_PANEL_MODAL_OPTIONS_TYPE",
                event.panel_modal.options_type
            );
        };
        //提交修改
        const submit = () => {
            event.show = false;
            let panel_data = Object.assign(
                { project_id: event.project_id },
                event.panel_modal
            );
            store
                .dispatch("panel-group/updatePanelItem", panel_data)
                .then((response) => {
                    showToast(
                        null,
                        "成功",
                        "组件" + panel_data.panel_name + "参数更新成功！"
                    );
                    ctx.emit("panel-modal-submit");
                })
                .catch((error) => {
                    event.show = true;
                    showToast(error);
                });
        };
        watch(
            [
                () => store.state["panel-group"]["panel-modal"]["panel_id"],
                () => store.state["panel-group"]["panel-modal"]["type"],
                () => store.state["panel-group"]["panel-modal"]["options"],
            ],
            (value) => {
                if (!!value) {
                    event.panel_modal =
                        store.state["panel-group"]["panel-modal"];
                }
            },
            "deep"
        );

        return {
            ...toRefs(event),
            change_options_type,
            submit,
        };
    },
};
</script>
<style lang="scss" scoped>
.modal-lg,
.modal-xl {
    max-width: 1000px !important;
}
</style>
