import {computed, reactive, toRefs, watch} from "@vue/composition-api";
import store from "@/store";
import {
	datasource_settings_default,
	panel_operator_list,
	query_default,
	query_type_list,
	time_query_range_list,
	time_query_unit_list
} from "@/views/panel-group/defaultSettings";
import {showToast} from "@/libs/utils/showToast";
import {routerParams} from "@/libs/utils/routerParams";

export default function queryModal() {
	// ------------------------------------------------
	// 引入响应数据
	// ------------------------------------------------
	const event = reactive({
		show: false,
		time_query_unit_list: time_query_unit_list,
		time_query_range_list: time_query_range_list,
		panel_operator_list: panel_operator_list,
		query_type_list: query_type_list,
		query_modal: query_default,
		datasource_list: [],
		table_list: [],
		field_list: [],
		//选中数据表的数据量
		table_count: 0,
		table_alert_text: "",

		project_id: computed(() => {
			return routerParams("project_id");
		}),
	});
	//监听查询弹窗数据变化
	watch(
			() => store.state["panel-group"]["query-modal"],
			(value) => {
				if (!!value) {
					event.query_modal = value;
				}
			}
	);
	//监听数据源列表变化
	watch(
			() => store.state["panel-group"]["datasource-list"],
			(value) => {
				if (!!value) {
					event.datasource_list = value;
				}
			}
	);
	//数据源id变化时，获取此数据源所有数据表
	watch(
			() => event.query_modal.datasource_id,
			(value) => {
				if (!!value) {
					showToast(null, "提示", "已切换数据库，正在获取数据表列表");
					event.table_count = 0;
					fetchTables(value);
				}
			}
	);
	//数据源id变化时，获取此数据源所有数据表
	watch(
			() => event.query_modal.table,
			(value) => {
				if (!!value) {
					showToast(null, "提示", "已切换数据表，正在获取字段列表");
					event.table_count = 0;
					fetchFields(event.query_modal.datasource_id, value);
				}
			}
	);
	//弹窗显示时，将show = true
	//   const on_modal_show=(e)=>{
	//     console.log('show',e.type)
	//     if(!event.show){
	//       event.show = true
	//     }
	//   }
	//添加条件
	const query_map_add = () => {
		if (event.query_modal.query_map.length < 3) {
			event.query_modal.query_map.push({
				field: "",
				operator: "",
				value: "",
			});
		} else {
			showToast(null, "提示：", "其他筛选条件上限为3", "warning");
		}
	};
	//删除条件
	const query_map_del = (index) => {
		event.query_modal.query_map.splice(index, 1);
	};
	//数据表提示文字
	const table_alert_text_fn = (status = true) => {
		if (status) {
			if (window.interval_table_alert_text) {
				clearInterval(window.interval_table_alert_text);
				window.interval_table_alert_text = null;
			}
			let second = 0;
			window.interval_table_alert_text = setInterval(() => {
				event.table_alert_text = "查询中，查询计时：" + second++ + " s";
				if (second > 30) {
					showToast(null, "错误：", "此数据表数据量过大，查询时间过长，请更换其他数据表");
					table_alert_text_fn(false);
				}
			}, 1000);
		} else {
			clearInterval(window.interval_table_alert_text);
			window.interval_table_alert_text = null;
			event.table_alert_text = "";
		}
	};
	//其他筛选条件提示文字
	const query_map_value_alert_text_fn = (operator) => {
		let text = "";
		if (["IN", "NOT IN"].includes(operator)) {
			text = "（数组条件请用英文逗号“,”分隔，例如：“1, 2, 3, 4, 5”）";
		}
		if (["BETWEEN", "NOT BETWEEN"].includes(operator)) {
			text = "（数组区间请用英文逗号“,”分隔，例如：“1, 10”）";
		}
		if (["LIKE", "NOT LIKE"].includes(operator)) {
			text = "（模糊查询请用英文符号“%”限定所需查询文本位置，例如：“%text”或“text%”）";
		}
		if (["EXP"].includes(operator)) {
			text = '（SQL查询支持使用SQL函数，例如：" IN(1,2,3,4,5) "）';
		}
		return text;
	};
	/**
	 * 获取数据源列表
	 */
	const fetchDatasourceList = () => {
		store
				.dispatch("panel-group/fetchDatasourceList", {
					project_id: event.project_id,
				})
				.then((response) => {
					let {list} = response.data;
					list.map((item) => {
						item.value = item.datasource_id;
						item.text = item.datasource_name + "（" + item.database + "）";
					});
					// console.log('datasource-list', list)
					store.commit("panel-group/UPDATE_DATASOURCE_LIST", list);
				})
				.catch((error) => {
					showToast(error);
				});
	};
	/**
	 * 获取数据源列表
	 */
	const fetchTables = (datasource_id) => {
		table_alert_text_fn(true);
		store
				.dispatch("panel-group/fetchTables", {
					datasource_id: datasource_id,
					project_id: event.project_id,
				})
				.then((response) => {
					showToast(null, "成功", "数据表列表获取成功");
					let list = response.data;
					let table_list = [];
					let table_old = event.query_modal.table;
					//若新数据表列表中存在旧表则保持，否则为空
					event.query_modal.table = list.includes(event.query_modal.table) ? table_old : "";
					list.map((item) => {
						table_list.push({
							text: item,
							value: item,
						});
					});
					event.table_list = table_list;
					table_alert_text_fn(false);
				})
				.catch((error) => {
					table_alert_text_fn(false);
					showToast(error);
				});
	};
	/**
	 * 获取数据源列表
	 */
	const fetchFields = (datasource_id, table) => {
		table_alert_text_fn(true);
		//清空字段列表
		event.field_list = [];
		store
				.dispatch("panel-group/fetchFields", {
					datasource_id: datasource_id,
					table: table,
					project_id: event.project_id,
				})
				.then((response) => {
					showToast(null, "成功", "字段列表获取成功");
					let {fields, count} = response.data;
					let fields_new = [];
					const time_query_field_old = event.query_modal.time_query_field;
					let is_exist_time_query_field_old = false;
					fields.map((item) => {
						item.value = item.name;
						item.text = item.name + " (" + item.type + ")";
						//判断新字段列表中是否存在原取用字段列表
						if (event.query_modal.fields.includes(item.name)) {
							fields_new.push(item.name);
						}
						//判断判断新字段列表中是否存在原时间查询字段
						if (item.name === time_query_field_old) {
							is_exist_time_query_field_old = true;
						}
					});
					event.query_modal.fields = fields_new;
					event.field_list = fields;
					event.table_count = count;
					//更新时间查询字段
					if (!is_exist_time_query_field_old) {
						event.query_modal.time_query_field = "";
					}
					//更新提醒文字
					table_alert_text_fn(false);
				})
				.catch((error) => {
					//更新提醒文字
					table_alert_text_fn(false);
					showToast(error);
				});
	};

	const submitQuery = () => {
		if (event.query_modal.query_id) {
			updateQuery();
		} else {
			addQuery();
		}
	};
	/**
	 * 新增query
	 */
	const addQuery = () => {
		store
				.dispatch("panel-group/addQuery", Object.assign({project_id: event.project_id}, event.query_modal))
				.then((response) => {
					showToast(null, "成功", "查询新增成功！");
					event.show = false;
					const {list} = response.data;
					formatQueryList(list);
				})
				.catch((error) => {
					console.log("error", error.response);
					let message = error.response.data.message;
					if (message.indexOf("SQLSTATE[23000]: Integrity constraint violation: 1062 Duplicate entry ") !== -1 && message.indexOf("for key 'query_name'") !== -1) {
						error.response.data.message = '"查询名称" 重复，请重新输入！';
					}
					showToast(error);
				});
	};
	/**
	 * 更新query
	 */
	const updateQuery = () => {
		store
				.dispatch("panel-group/updateQuery", Object.assign({project_id: event.project_id}, event.query_modal))
				.then((response) => {
					showToast(null, "成功", "查询更新成功！");
					event.show = false;
					const {list} = response.data;
					formatQueryList(list);
				})
				.catch((error) => {
					let message = error.response.data.message;
					if (message.indexOf("SQLSTATE[23000]: Integrity constraint violation: 1062 Duplicate entry ") !== -1 && message.indexOf("for key 'query_name'") !== -1) {
						error.response.data.message = '"查询名称" 重复，请重新输入！';
					}
					showToast(error);
				});
	};

	/**
	 * 整理query_list数据格式，用于select
	 * @param list
	 */
	const formatQueryList = (list) => {
		list.map((item) => {
			item.value = item.query_id;
			item.text = item.query_name;
		});
		store.commit("panel-group/UPDATE_QUERY_LIST", list);
	};

	/**
	 * 打开数据源弹窗
	 * @param datasource_id
	 */
	const datasource_modal_show = (datasource_id) => {
		let datasource = datasource_settings_default;
		for (let datasource_item of event.datasource_list) {
			if (datasource_item.datasource_id === datasource_id) {
				datasource = datasource_item;
				break;
			}
		}
		store.commit("panel-group/UPDATE_DATASOURCE_MODAL", datasource);
	};

	return {
		...toRefs(event),
		fetchDatasourceList,
		submitQuery,
		query_map_add,
		query_map_del,
		query_map_value_alert_text_fn,
		datasource_modal_show,
	};
}
