<template>
	<div>
		<h5 class="mt-2 text-center">表单内容开发中...</h5>
		<!--	<component-->
		<!--			:is="'panel-component-' + panel_modal.type + '-settings'"-->
		<!--			:ref="'panel_' + panel_modal.panel_id + '_settings'"-->
		<!--			:fields="panel_modal.query_temp_data.fields"-->
		<!--			:options="panel_modal.options"-->
		<!--			class="w-100 h-100 no-drag zindex-0"-->
		<!--	></component>-->
	</div>
</template>

<script>
	export default {
		name: "PanelModalParamsForm",
	};
</script>

<style scoped></style>
